.content.custom {
  padding-top: 1rem;
}

.custom-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  text-align: center;
  /* Вы можете настроить z-index по вашему усмотрению */
  /* Другие стили по вашему усмотрению */
}

.token_again {
  display: none;
}

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}


.token_resent {
  position: relative;
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Центрирование текста */
  margin-top: 10px;
  font-size: 17px;
  /* color: green; */
}

.st0 {
  fill: rgba(233, 236, 239, .5);
}

.input-select {
  margin-top: 1px;
  margin-left: 10px;
}

input.select {
  min-height: 27px;
  height: 27px;
}

.editUser {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.editUser svg {
  width: 12px;
  margin-right: 5px;
  fill: rgba(233, 236, 239, .5);
}

.block-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cross {
  width: 25px;
  height: 25px;
}

.cross path {
  fill: red;
}

.product-svg {
  width: 25px;
  height: 25px;
  fill: #545e69;
}


/*  */

ul {
  list-style-type: none;
  /* Убирает маркеры */
  padding-left: 0;
  /* Убирает отступ слева */
}

.category-item {
  position: relative;
  cursor: pointer;
}

.category-item::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
}

.category-item.has-children .icon-wrapper {
  display: inline-block;
  width: 16px;
  /* Ширина иконки */
  margin-right: 5px;
}

.category-item .toggle-icon {
  transition: transform 0.2s ease;
}

.category-item .toggle-icon.open {
  transform: rotate(90deg);
}

.category-name {
  padding: 5px 0;
}

.nested-categories {
  padding-left: 20px;
  /* Отступ для дочерних элементов */
  list-style-type: none;
}

/* Удалить линию у последнего элемента, так как она не нужна */
.category-item:last-child::before {
  display: none;
}

/* Стили для различных уровней вложенности */
.category-item.level-1::before {
  left: 0;
}

.category-item.level-2::before {
  left: 20px;
}

.category-item.level-3::before {
  left: 40px;
}

/* Дерево многоуровневое
-------------------------------- */
.menu-category {
  margin-left: 3rem;
}

.multi-menu #multi-derevo {
  width: 220px;
  /* блок под дерево */
  /* border: solid; */
  /* границы блока */
  border-color: silver gray gray silver;
  /* border-width: 2px; */
  padding: 0 0 1em 0;
  /* нижний отступ */
  /* font-size: 1.3em; */
}

.multi-menu span {
  /* обертка пункта */
  text-decoration: none;
  display: block;
  /* растянем до правого края блока */
  margin: 0 0 0 1.2em;
  background-color: transparent;
  border: solid silver;
  /* цвет линий */
  border-width: 0 0 1px 1px;
  /* границы: низ и лево */
}

.multi-menu span a {
  /* тест элемента дерева */
  display: block;
  position: relative;
  top: .95em;
  /* смещаем узел на середину линии */
  background-color: #222e3c;
  /* закраска в цвет фона обязательна иначе будет видно линию */
  margin: 0 0 .2em .7em;
  /* делаем промежуток между узлами, отодвигаем левее  */
  padding: 0 0.3em;
  color: rgba(233, 236, 239, .5);
  /* небольшой отступ от линии */
}

.multi-menu ul,
.multi-menu li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.multi-menu ul li {
  line-height: 1.2em;
}

.multi-menu ul li ul {
  /* display: none; */
  /* узлы свернуты */
}

.multi-menu ul li ul li {
  margin: 0 0 0 1.2em;
  border-left: 1px solid silver;
  /* цвет вертикальной линии между узлами */
}

.multi-menu li.last {
  /* последний узел, соединительную линию к след. узлу убираем */
  border: none;
}

.multi-menu .marker {
  /* маркер раскрытия списка в закрытом состоянии */
  border-color: transparent transparent transparent gray;
  border-style: solid;
  border-width: .25em 0 .25em .5em;
  margin: .35em .25em 0 0;
  float: left;
  width: 0px;
  height: 0px;
  line-height: 0px;
}

.multi-menu .marker.open {
  /* маркер раскрытия списка в открытом состоянии */
  border-color: gray transparent transparent transparent;
  border-width: .5em .25em 0 .25em;
}

.hiddenList {
  display: none;
}

.visibleList {
  display: block;
}

/*  */


.fade-in-out {
  animation: fadeInOut 3s ease-in-out forwards;
}

.error {
  color: red;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/* .slide-in-out {
  position: fixed;
  top: -100%; 
  left: 0;
  width: 100%;
  animation: slideInOut 1s cubic-bezier(0.32, 0.64, 0.45, 1) forwards;
}

@keyframes slideInOut {
  0% {
    top: -100%; 
  }
  20%, 80% {
    top: 0;
  }
  100% {
    top: -100%; 
  }
} */


.attribute {
  width: 27px;
  height: 27px;
  fill: #545e69;
}

.multiple-td {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
}

/* Стили для подложки */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Стили для модального окна */
.custom-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
  width: 100%;
  max-width: 577px;
}

.button-custom-modal{
  justify-content: center;
  gap: 25px;
}

.container-work {
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  height: 100vh; /* Высота контейнера равна 100% от высоты видимой части экрана */
  text-align: center; /* Центрирование текста внутри контейнера */
}

/* Дополнительно, для лучшего визуального восприятия, можете центрировать текст внутри div элементов */
.container-work div {
  width: 100%; /* Чтобы текст внутри div элементов тоже был по центру */
}

@keyframes fadeChange {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.countdown {
  animation: fadeChange 0.5s ease-out;
}

.allFillingImg {
  height: 50px;
}