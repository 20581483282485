/* Register.module.css */

.registration-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
}

.role-block {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 155px;
  background-color: #fff;
  --bs-card-border-color: var(--bs-border-color-translucent);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 8px;
  padding-bottom: 10px;
}

.role-block p {
  margin-bottom: 5px;
}
.role-block a {
  color: var(--bs-body-color);
}