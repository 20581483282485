/* Стили для появления уведомления */
.alert-enter {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .alert-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  
  .alert-exit {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .alert-exit-active {
    opacity: 0;
    transform: translateY(-100%);
  }
  