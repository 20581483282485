.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #007bff; /* Синий цвет, можно изменить на любой другой */
    cursor: pointer;
    position: fixed; /* Делаем кнопку фиксированной */
    top: 50%; /* Располагаем кнопку в верхней части страницы */
    left: 50%; /* Располагаем кнопку по центру страницы */
    transform: translate(-50%, -50%); /* Центрируем кнопку относительно её собственных размеров */
    z-index: 9999; /* Устанавливаем высокий z-index, чтобы кнопка оставалась поверх других элементов */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #007bff, #00ccff); /* Градиент синего цвета */
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
