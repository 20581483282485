#VKIDSDKAuthButton {
  all: unset;
  cursor: pointer;
  //border: none;
  //background: none;
  //padding: 0;
  //margin: 0;
}
.vk-icon {
  width: 37px;
  height: 37px;
}