.divider-with-circle {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 20px 0;

  .line {
    flex-grow: 1;
    height: 2px;
    background-color: #ccc;
  }

  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    z-index: 1;

    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
