.container-button {

}
.auth-classic-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.auth-social-buttons-container{
  margin-top: 1rem;
}
.auth-social-header {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.auth-social-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.telegram-href {
  display: none;
}