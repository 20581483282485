/* Стили для модального окна */
.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Убедитесь, что модальное окно выше остальных */
}

.ReactModal__Content {
  width: 100%;
  max-width: 550px;
  max-height: 200px;
  overflow-y: auto; /* Добавьте прокрутку, если контент превышает высоту */
  margin: auto;
}
